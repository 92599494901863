import { useState } from "react";
import { useNetwork, useAccount, useContract, useSigner } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { IoCloudUpload, IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import shallow from "zustand/shallow";
import useStore from "../state/store";
import Modal from "./Modal";
import { DEFAULT_CHAIN_ID, SOURCE_REQUEST_DATA } from "../state/consts";
import { FrameDeployerABI, ScriptyBuilderABI } from "../contracts/abis";

import { toBytes } from "../utils/data";
import {
  getEtherScanLink,
  getFrameInfraAddress,
  createWrappedRequest,
  orderScriptsByName,
  getSourceBufferSize,
} from "../utils/web3";

const PublishModal = () => {
  const {
    publishModalActive,
    setPublishModalActive,
    tosConfirmed,
    setTosConfirmed,
    selectedLibOptions,
    source,
  } = useStore(
    (state) => ({
      selectedLibOptions: state.selectedLibOptions,
      publishModalActive: state.publishModalActive,
      setPublishModalActive: state.setPublishModalActive,
      tosConfirmed: state.tosConfirmed,
      setTosConfirmed: state.setTosConfirmed,
      selectedIds: state.selectedIds,
      coreDeps: state.coreDeps,
      source: state.source,
    }),
    shallow
  );

  const navigate = useNavigate();
  const { chain } = useNetwork();
  const { isConnected } = useAccount();
  const { data: signer } = useSigner();
  const [frameName, setFrameName] = useState("");
  const [frameDesc, setFrameDesc] = useState("");
  const [frameSymbol, setFrameSymbol] = useState("");
  const [isDeploying, setIsDeploying] = useState(false);
  const [newFrameAddress, setNewFrameAddress] = useState("");
  const chainId = chain?.id || DEFAULT_CHAIN_ID;

  const frameDeployer = useContract({
    addressOrName: getFrameInfraAddress("FrameDeployer", chainId),
    contractInterface: FrameDeployerABI,
    signerOrProvider: signer,
  });

  const scriptyBuilder = useContract({
    addressOrName: getFrameInfraAddress("ScriptyBuilder", chainId),
    contractInterface: ScriptyBuilderABI,
    signerOrProvider: signer,
  });

  const onPublish = async () => {
    // TODO: Check if source script name is available
    const sourceContent = btoa(source);
    const sourceBufferSize = getSourceBufferSize(sourceContent);
    const libsRequests = orderScriptsByName(selectedLibOptions).map(
      (libOption) =>
        createWrappedRequest(
          libOption,
          getFrameInfraAddress("FrameTools", chainId)
        )
    );
    const sourceRequest = createWrappedRequest(
      {
        name: frameName + "-source",
        ...SOURCE_REQUEST_DATA,
      },
      getFrameInfraAddress("FrameTools", chainId)
    );
    const requests = libsRequests.concat([sourceRequest]);
    const libsBufferSize =
      await scriptyBuilder.getBufferSizeForURLSafeHTMLWrapped(libsRequests);
    const bufferSize = libsBufferSize.toNumber() + sourceBufferSize;

    console.log(
      {
        name: frameName,
        description: frameDesc,
        symbol: frameSymbol,
      },
      toBytes(sourceContent),
      requests,
      bufferSize
    );

    const createCall = await frameDeployer.createFrameWithScript(
      {
        name: frameName,
        description: frameDesc,
        symbol: frameSymbol,
      },
      toBytes(sourceContent),
      requests,
      bufferSize
    );

    setIsDeploying(true);

    try {
      const createResult = await createCall.wait();
      console.log(createResult);
      const newFrameAddress = createResult.logs[
        createResult.logs.length - 1
      ]?.data.replace("000000000000000000000000", "");
      setNewFrameAddress(newFrameAddress);
    } catch (e) {
      console.log(e);
    }

    setIsDeploying(false);
  };

  return (
    <Modal
      active={publishModalActive}
      onClose={() => {
        setPublishModalActive(false);
      }}
    >
      {isConnected ? (
        <div>
          {isDeploying && (
            <div className="h-full w-full flex flex-col justify-center items-center space-y-4">
              <IoCloudUpload className="text-2xl" />
              <span className="text-center">
                Publishing {frameName} to the {chain?.name} network
              </span>
            </div>
          )}

          {newFrameAddress && (
            <div>
              <div className="mb-4 font-semibold">
                <span>Success</span>
              </div>
              <div className="mb-6">
                <p>
                  {frameName} has been published to the {chain?.name} network at
                </p>
                <p>
                  <a
                    className="link"
                    target="_blank"
                    href={
                      // getMarketplaceLink(chainToUse?.id) + address + "/0"
                      getEtherScanLink(chainId) + "/address/" + newFrameAddress
                    }
                  >
                    {newFrameAddress}
                  </a>
                </p>
              </div>
              <button
                onClick={() => {
                  navigate("/frame/eth/" + chainId + "/" + newFrameAddress);
                }}
                className={"btn btn-primary btn-block"}
              >
                <IoSearch className="mr-2" /> View
              </button>
            </div>
          )}

          {!isDeploying && !newFrameAddress && (
            <div>
              <div className="mb-6 font-semibold">
                <span>Publish NFT (1 of 1 ERC-721)</span>
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  className="input input-bordered w-full"
                  placeholder="Name"
                  onChange={(e) => {
                    setFrameName(e.target.value);
                  }}
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  className="input input-bordered w-full"
                  placeholder="Description"
                  onChange={(e) => {
                    setFrameDesc(e.target.value);
                  }}
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  className="input input-bordered w-full"
                  placeholder="Token symbol"
                  onChange={(e) => {
                    setFrameSymbol(e.target.value);
                  }}
                />
              </div>
              <div className="form-control mb-4">
                <label className="label">
                  <span className="label-text">
                    I agree with the frame.tools{" "}
                    <a
                      className="link"
                      href="/terms-of-use.html"
                      target="_blank"
                    >
                      terms of use
                    </a>
                  </span>
                  <input
                    type="checkbox"
                    checked={tosConfirmed && "checked"}
                    className="checkbox checkbox-primary cursor-pointer"
                    onClick={() => setTosConfirmed(!tosConfirmed)}
                  />
                </label>
              </div>
              <div>
                <button
                  onClick={onPublish}
                  className={
                    "btn btn-primary btn-block " +
                    (!tosConfirmed || !frameName ? "btn-disabled" : "")
                  }
                >
                  <IoCloudUpload className="mr-2" /> Publish to the{" "}
                  {chain?.name} network
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-center space-y-4">
          <span className="text-lg font-semibold">Connect wallet</span>
          <span>Connect a wallet to publish.</span>
          <ConnectButton />
        </div>
      )}
    </Modal>
  );
};

export default PublishModal;
