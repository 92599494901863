const Modal = ({ active = false, children, onClose }) => {
  return active ? (
    <div
      id="modal"
      className="z-10 absolute top-0 left-0 w-full h-full flex items-center justify-center"
    >
      <div
        className="absolute w-full h-full top-0 left-0 bg-slate-100 bg-opacity-10"
        onClick={onClose}
      ></div>
      <div className="modal-box">{children}</div>
    </div>
  ) : null;
};

export default Modal;
