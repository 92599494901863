import * as ethers from "ethers";

export const GWEI_IN_ETH = 1000000000;
export const GAS_PRICE = 5;
export const ETH_USD_PRICE = 1200;

export const toBytes = ethers.utils.toUtf8Bytes;
export const fromBytes = ethers.utils.toUtf8String;
export const hexlify = ethers.utils.hexlify;
export const hexEncode = (str) => {
  let hex, i;
  let result = "";
  for (i = 0; i < str.length; i++) {
    hex = str.charCodeAt(i).toString(16);
    result += ("000" + hex).slice(-4);
  }

  return result;
};
export const formatEther = ethers.utils.formatEther;

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

export const staggerStore = async (contract, key, dataString, chunks) => {
  const stringChunks = chunkSubstr(
    dataString,
    Math.ceil(dataString.length / chunks)
  );

  for (let i = 0; i < stringChunks.length; i++) {
    await contract.saveData(key, i, toBytes(stringChunks[i]));
    console.log(`Stored ${key} page ${i}`);
  }
};

export const constructRenderIndex = (assetPageSizes, maxRenderPageSize) => {
  const renderIndex = [];
  let totalAssetPagesCount = 0;
  for (let idx = 0; idx < assetPageSizes.length; idx++) {
    totalAssetPagesCount = totalAssetPagesCount + assetPageSizes[idx];
  }

  const assetPages = assetPageSizes
    .map((a) => {
      const arr = new Array(a);
      for (let i = 0; i < arr.length; i++) {
        arr[i] = i;
      }
      return arr;
    })
    .reduce(
      (previousValue, currentValue) => previousValue.concat(currentValue),
      []
    );

  let assetCursor = 0;
  let pageCursor = 0;
  let pagesTraversedCounter = 0;
  let totalPagesTraversedCounter = 0;
  let currentStartAsset = 0;
  let currentStartPage = 0;

  for (let adx = 0; adx < assetPages.length; adx++) {
    pagesTraversedCounter++;
    totalPagesTraversedCounter++;

    // Move to next asset when page value changes
    const value = assetPages[adx];
    const prevValue = assetPages[adx - 1];
    if (value <= prevValue) {
      assetCursor++;
    }

    pageCursor = value;

    // At the start of a new render page, record the new position
    if (pagesTraversedCounter === 1) {
      currentStartAsset = assetCursor;
      currentStartPage = pageCursor;
    }

    // Reached the end of either the render page or all assets
    if (
      pagesTraversedCounter === maxRenderPageSize ||
      totalPagesTraversedCounter === assetPages.length
    ) {
      // Add item
      renderIndex.push([
        currentStartAsset,
        assetCursor,
        currentStartPage,
        pageCursor,
      ]);
      pagesTraversedCounter = 0;
    }
  }

  return renderIndex;
};

export const roughSizeOfObject = (object) => {
  var objectList = [];
  var stack = [object];
  var bytes = 0;

  while (stack.length) {
    var value = stack.pop();

    if (typeof value === "boolean") {
      bytes += 4;
    } else if (typeof value === "string") {
      bytes += value.length * 2;
    } else if (typeof value === "number") {
      bytes += 8;
    } else if (typeof value === "object" && objectList.indexOf(value) === -1) {
      objectList.push(value);

      for (var i in value) {
        stack.push(value[i]);
      }
    }
  }
  return bytes;
};

export const calcStoragePages = (object) => {
  return Math.ceil(roughSizeOfObject(object) / 23000);
};

export const renderContractCost = (gasPrice, ethUsdPrice) => {
  const totalGas = 3253813 * gasPrice;
  const totalCostInEth = totalGas / GWEI_IN_ETH;
  console.log("renderContractCost", totalGas, totalCostInEth);

  return totalCostInEth * ethUsdPrice;
};

export const publishCost = (newCode) => {
  const bytes = roughSizeOfObject(newCode);
  const gasPerKb = 640000;

  // 5 gwei
  const gasPriceInEth = GAS_PRICE / GWEI_IN_ETH;
  const sourceCost = (bytes / 2000) * gasPerKb * gasPriceInEth * ETH_USD_PRICE;
  // const contractCost = renderContractCost(GAS_PRICE, ETH_USD_PRICE);
  const contractCost = 0.0317594 * GAS_PRICE * ETH_USD_PRICE;

  return sourceCost + contractCost;
};

export const buf2hex = (buffer) => {
  // buffer is an ArrayBuffer
  return [...new Uint8Array(buffer)]
    .map((x) => x.toString(16).padStart(2, "0"))
    .join("");
};

export default {
  toBytes,
  chunkSubstr,
  staggerStore,
  constructRenderIndex,
  roughSizeOfObject,
  calcStoragePages,
  publishCost,
  buf2hex,
};
