import { ConnectButton } from "@rainbow-me/rainbowkit";
import { IoBook, IoSearch, IoAddCircle, IoLogoGithub } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";

import Tabs from "./Tabs";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedIndex = location.pathname.match("/frame") ? 1 : 0;

  return (
    <div className="w-full flex justify-between items-center py-2 px-4 sm:px-4 border border-b border-base-300 pb-2">
      <div className="flex flex-1 flex-row sm:space-y-0 items-center">
        <div className="flex items-center flex-1 items-center">
          <span className="text-xs mr-4 text-md sm:text-lg font-bold">
            {"</>"}
          </span>
          <Tabs
            activeIndex={selectedIndex}
            onChange={(i) => {
              if (i === 0) {
                navigate("/");
              } else if (i === 1) {
                navigate("/frames");
              } else {
                window.open("https://github.com/caszete/frame-tools", "_blank");
              }
            }}
            options={[
              <span className="flex items-center space-x-4">
                <IoAddCircle className="text-lg" />
                <span className="hidden sm:block">Create</span>
              </span>,
              <span className="flex items-center space-x-4">
                <IoSearch className="text-lg" />
                <span className="hidden sm:block">View</span>
              </span>,
              <span className="flex items-center space-x-4">
                <IoLogoGithub className="text-lg" />
                <span className="hidden sm:block">GitHub</span>
              </span>,
            ]}
          />
        </div>

        <div className="flex items-center space-x-4 text-xs sm:text-sm">
          <ConnectButton />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
