import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import {
  configureChains,
  createClient as createWgmiClient,
  WagmiConfig,
} from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { CHAINS } from "./state/consts";

import "./index.css";
import Studio from "./pages/Studio";
import Frames from "./pages/Frames";
import Frame from "./pages/Frame";
import reportWebVitals from "./reportWebVitals";

// console.log("process.env", process.env);

// WGMI
const { chains, provider } = configureChains(CHAINS, [
  alchemyProvider({ apiKey: "bT2Gi8WI5-jp62yzI6H2yakitNqytvvW" }),
  publicProvider(),
]);

const { connectors } = getDefaultWallets({
  appName: "Frame",
  chains,
});

console.log("chains", chains);

const wagmiClient = createWgmiClient({
  autoConnect: false,
  connectors,
  provider,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={darkTheme()}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Studio />} />
            <Route path="/frames" element={<Frames />} />
            <Route
              path="/frame/:netId/:netVersion/:address"
              element={<Frame />}
            />
          </Routes>
        </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
