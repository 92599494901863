import { useState, useEffect } from "react";
import shallow from "zustand/shallow";
import { Link, useNavigate } from "react-router-dom";
import ethAddress from "ethereum-address";

import { CHAINS, DEFAULT_CHAIN_ID } from "../state/consts";
import useStore from "../state/store";
import Navbar from "../components/Navbar";
import { getRpcUrl, getChain } from "../utils/web3";

import { useNetwork, useAccount, useConnect } from "wagmi";
import { useConnectModal, ConnectButton } from "@rainbow-me/rainbowkit";
import { IoCloudOffline, IoCloudDownload, IoCloud } from "react-icons/io5";

const Frames = () => {
  const [address, setAddress] = useState("");
  const { recentFrames } = useStore(
    (state) => ({
      recentFrames: state.recentFrames,
    }),
    shallow
  );

  useEffect(() => {}, []);

  const navigate = useNavigate();
  const { chain } = useNetwork();
  const chainToUse = chain || getChain(DEFAULT_CHAIN_ID);
  const chainId = chainToUse.id;

  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const isAddressValid = ethAddress.isAddress(address);

  console.log("network", chainToUse);

  return (
    <div className="relative mx-auto">
      <Navbar />
      <div className="flex flex-col items-center justify-center -mt-px sm:mt-0 sm:py-12 p-4 ">
        <div
          style={{ width: "100%", maxWidth: "640px" }}
          className="border border-base-300 w-full bg-base-200"
        >
          <div className="p-4">
            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
              <input
                className="input input-bordered w-full"
                placeholder="Frame contract address"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
              <button
                className={
                  "btn " +
                  (address.length && isAddressValid ? "" : "btn-disabled")
                }
                onClick={async () => {
                  navigate("/frame/eth/" + chainToUse.id + "/" + address);
                }}
              >
                <IoCloudDownload className="mr-2" /> Render
              </button>
            </div>
            {recentFrames.length ? (
              <div className="mt-4">
                <span className="font-semibold text-sm">Recent</span>
                <table className="w-full mt-2">
                  <tbody>
                    {recentFrames.map((f, i) => {
                      const urlChain = CHAINS.find(
                        (o) => String(o.id) === f.netVersion
                      );

                      return (
                        <tr key={i}>
                          {/* <div
                          className="mt-2 flex flex-col sm:flex-row justify-between sm:space-x-2"
                          key={i}
                        > */}
                          <td>
                            <div className="flex flex-col space-y-0.5">
                              <span className="text-xs">{f.name}</span>
                              <span
                                className="sm:hidden link text-xs"
                                onClick={() => {
                                  navigate(
                                    "/frame/eth/" +
                                      f.netVersion +
                                      "/" +
                                      f.address
                                  );
                                }}
                              >
                                {f.address}
                              </span>
                            </div>
                          </td>
                          <td className="px-2">
                            <span
                              className="hidden sm:block link text-xs"
                              onClick={() => {
                                navigate(
                                  "/frame/eth/" + f.netVersion + "/" + f.address
                                );
                              }}
                            >
                              {f.address}
                            </span>
                          </td>
                          <td>
                            <span className="hidden sm:flex badge badge-ghost">
                              <IoCloud className="mr-2" />
                              {urlChain?.name}
                            </span>
                          </td>
                          {/* </div> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frames;
