export const ADDRESSES_MAP = {
  FrameTools: {
    5: "0x471eC0A7B0a063e7d821e4d3Fc54C50885D5a7E4",
    31337: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
  },
  FrameDeployer: {
    5: "0x94Fe562f97C2469f29A736E0825597f9D78bd7E7",
    31337: "0xa513E6E4b8f2a923D98304ec87F64353C4D5C853",
  },
  ScriptyBuilder: {
    5: "0xc9AB9815d4D5461F3b53Ebd857b6582E82A45C49",
    31337: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
  },
};
