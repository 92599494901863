import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import useStore from "../state/store";

import Navbar from "../components/Navbar";
import FrameView from "../components/FrameView";

const Frame = () => {
  const { address, netVersion } = useParams();
  const navigate = useNavigate();
  const { recentFrames, setRecentFrames } = useStore(
    (state) => ({
      recentFrames: state.recentFrames,
      setRecentFrames: state.setRecentFrames,
    }),
    shallow
  );

  return (
    <div className="relative mx-auto h-screen">
      <div className="hidden sm:block w-full">
        <Navbar />
      </div>
      <div className="relative flex justify-center items-center -mt-px sm:mt-0 sm:py-12 sm:px-4">
        <FrameView
          address={address}
          netVersion={netVersion}
          onClose={() => {
            navigate("/frames");
          }}
          onRender={(address, netVersion, name) => {
            console.log("onrender", recentFrames);
            if (
              !recentFrames.find(
                (o) => o.address === address && o.netVersion === netVersion
              )
            ) {
              setRecentFrames(
                recentFrames.concat([{ address, netVersion, name }])
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default Frame;
