import shallow from "zustand/shallow";
import { useNetwork } from "wagmi";
import useStore from "../state/store";
import { DEFAULT_CHAIN_ID, importIds } from "../state/consts";
import { getChain, getLibContent } from "../utils/web3";

import {
  IoRadioButtonOnOutline,
  IoRadioButtonOffOutline,
} from "react-icons/io5";
const ImportTile = ({ name, onClick, selected }) => (
  <div
    className={`relative rounded-lg ${
      selected ? "bg-base-300" : "bg-base-200"
    } px-6 py-5 shadow-sm flex items-center space-x-3`}
  >
    <div className="flex-1 min-w-0 flex items-center">
      {selected ? (
        <IoRadioButtonOnOutline className="mr-2" />
      ) : (
        <IoRadioButtonOffOutline className="mr-2" />
      )}
      <span className="cursor-pointer focus:outline-none" onClick={onClick}>
        <span className="absolute inset-0"></span>
        <div
          className={`text-sm font-medium flex items-center ${
            selected ? "opacity-100" : "opacity-50"
          }`}
        >
          {name}
        </div>
      </span>
    </div>
  </div>
);

const ImportSelector = () => {
  const {
    libOptions,
    selectedLibOptions,
    setSelectedLibOptions,
    setLibOptions,
  } = useStore(
    (state) => ({
      libs: state.libs,
      libOptions: state.libOptions,
      selectedLibOptions: state.selectedLibOptions,
      setSelectedIds: state.setSelectedIds,
      setImports: state.setImports,
      setLibs: state.setLibs,
      setSelectedLibOptions: state.setSelectedLibOptions,
      setLibOptions: state.setLibOptions,
    }),
    shallow
  );

  const { chain } = useNetwork();
  const chainToUse = chain || getChain(DEFAULT_CHAIN_ID);
  const chainId = chainToUse.id;
  const selectedNames = selectedLibOptions.map((o) => o.name);

  const select = (name) => {
    setSelectedLibOptions(
      selectedLibOptions.concat(libOptions.find((o) => o.name === name))
    );
  };

  const deselect = (name) => {
    console.log("deselect", name, selectedLibOptions);
    setSelectedLibOptions(selectedLibOptions.filter((i) => i.name !== name));
  };

  const libClicked = async (name) => {
    // Get import data from on-chain if not there yet
    const option = libOptions.find((o) => o.name === name);

    // Fetch and store Libs
    if (!option.scriptData) {
      console.log(option);
      const libData = await getLibContent(name, chainId);
      console.log(libData);
      setLibOptions(
        libOptions.map((o) =>
          o.name === name ? { ...o, scriptData: libData } : o
        )
      );
    }

    if (selectedNames.indexOf(name) > -1) {
      deselect(name);
    } else {
      select(name);
    }
  };

  const options = libOptions.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  console.log(libOptions, selectedLibOptions, selectedNames);

  return (
    <div className="h-full overflow-scroll">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 p-4">
        {options.map((opt, i) => (
          <ImportTile
            key={i}
            name={opt.name}
            onClick={() => libClicked(opt.name)}
            selected={selectedNames.indexOf(opt.name) > -1}
          />
        ))}
      </div>
    </div>
  );
};

export default ImportSelector;
