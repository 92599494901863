import CreatorPanel from "../components/CreatorPanel";
import RenderPanel from "../components/RenderPanel";
import Navbar from "../components/Navbar";
import PublishModal from "../components/PublishModal";

const Studio = () => {
  return (
    <div>
      <div className="relative mx-auto h-screen flex flex-col">
        <Navbar />
        <div className="border border-base-300 sm:border-none sm:grid sm:grid-cols-2 flex-1">
          <RenderPanel />
          <CreatorPanel />
        </div>
      </div>
      <PublishModal />
    </div>
  );
};

export default Studio;
