import { BsFillFileEarmarkCodeFill } from "react-icons/bs";
import { IoCloudDownloadSharp } from "react-icons/io5";
import shallow from "zustand/shallow";
import useStore from "../state/store";

const CreatorTabs = () => {
  const {
    selectedCreatorTab,
    setSelectedCreatorTab,
    tempSource,
    source,
    selectedIds,
  } = useStore(
    (state) => ({
      selectedCreatorTab: state.selectedCreatorTab,
      setSelectedCreatorTab: state.setSelectedCreatorTab,
      tempSource: state.tempSource,
      source: state.source,
      selectedIds: state.selectedIds,
    }),
    shallow
  );

  const hasChanges = tempSource !== source;

  return (
    <div className="flex space-x-2 text-sm">
      <button
        onClick={() => setSelectedCreatorTab(0)}
        className={`btn btn-sm btn-ghost gap-2 opacity-${
          selectedCreatorTab === 0 ? "100" : "50"
        } hover:opacity-100 cursor-pointer`}
      >
        <BsFillFileEarmarkCodeFill />
        <span className="flex items-center">
          <span className="hidden sm:block">Source</span>
          <span className="hidden sm:block opacity-50 sm:ml-2">javascript</span>
          <span className="hidden sm:block w-1">{hasChanges ? "*" : " "}</span>
        </span>
      </button>
      <button
        onClick={() => setSelectedCreatorTab(1)}
        className={`btn btn-sm btn-ghost gap-2 opacity-${
          selectedCreatorTab === 1 ? "100" : "50"
        } hover:opacity-100 cursor-pointer`}
      >
        <IoCloudDownloadSharp />
        <span className="hidden sm:block">
          Imports{" "}
          {selectedIds.length > 2 ? "(" + (selectedIds.length - 2) + ")" : ""}
        </span>
      </button>
    </div>
  );
};

export default CreatorTabs;
