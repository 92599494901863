import { useState } from "react";
import shallow from "zustand/shallow";
import { useNetwork } from "wagmi";

import { getLibContent, orderScriptsByName } from "../utils/web3";
import useStore from "../state/store";
import { DEFAULT_CHAIN_ID, EX_THREE_2_SOURCE } from "../state/consts";
import useResizeObserver from "use-resize-observer";
import { IoExpand, IoRefreshCircle } from "react-icons/io5";

import p51 from "../assets/p5-1.jpg";
import p52 from "../assets/p5-2.jpg";
import three2 from "../assets/three-2.jpg";

import {
  importIds,
  EX_P5_1_SOURCE,
  EX_P5_2_SOURCE,
  EX_P5_3_SOURCE,
  //
  WRAPPER_MAP,
  getWrapper,
} from "../state/consts";

const RenderFrame = ({ source, imports, height, width }) => {
  const { setRenderedHtml } = useStore(
    (state) => ({
      setRenderedHtml: state.setRenderedHtml,
    }),
    shallow
  );

  if (imports && source) {
    const sourceWrapper = getWrapper("jsmod-b64");
    const sourceScript = sourceWrapper[0] + btoa(source) + sourceWrapper[1];
    const libs = imports.reduce((a, b) => a + b, "");
    const html =
      WRAPPER_MAP.html[0] + libs + sourceScript + WRAPPER_MAP.html[1];

    setRenderedHtml(html);

    return (
      <iframe
        className="w-full h-full"
        title="frame"
        style={{ backgroundColor: "#fff" }}
        srcDoc={html}
      />
    );
  }
};

const RenderPanel = () => {
  const {
    libOptions,
    selectedLibOptions,
    setSelectedLibOptions,
    libs,
    setLibs,
    source,
    localImports,
    wrappers,
    renderedHtml,
    setSource,
    setTempSource,
    setLibOptions,
  } = useStore(
    (state) => ({
      coreDeps: state.coreDeps,
      selectedIds: state.selectedIds,
      setSelectedIds: state.setSelectedIds,
      libOptions: state.libOptions,
      selectedLibOptions: state.selectedLibOptions,
      setSelectedLibOptions: state.setSelectedLibOptions,
      libs: state.libs,
      setLibs: state.setLibs,
      source: state.source,
      imports: state.imports,
      setImports: state.setImports,
      localImports: state.localImports,
      wrappers: state.wrappers,
      renderedHtml: state.renderedHtml,
      setSource: state.setSource,
      setTempSource: state.setTempSource,
      setLibOptions: state.setLibOptions,
    }),
    shallow
  );

  const [isFetching, setIsFetching] = useState(false);
  const [showFrame, setShowFrame] = useState(true);
  const { chain } = useNetwork();
  const chainId = chain?.id || DEFAULT_CHAIN_ID;

  const getAndSaveImportsIfNeeded = async (names) => {
    console.log(names, libs);
    let latestOptions = [];
    setIsFetching(true);
    for (const name of names) {
      const option = libOptions.find((o) => o.name === name);

      if (!option.scriptData) {
        console.log(option);
        const libData = await getLibContent(name, chainId);
        console.log(libData);
        latestOptions.push({ ...option, scriptData: libData });
      } else {
        latestOptions.push(option);
      }
    }

    console.log("updated", latestOptions);

    setLibOptions(
      libOptions.map((o) => {
        const updated = latestOptions.find((up) => up.name === o.name);
        if (updated) {
          return updated;
        }
        return o;
      })
    );
    setSelectedLibOptions(latestOptions);
    setIsFetching(false);

    return;
  };

  const localImportsData = localImports
    ?.map((ld, i) => {
      const wrapper = wrappers.find((w) => w.id === ld.wrapper);
      if (wrapper) {
        return {
          html: wrapper.html[0] + ld.data + wrapper.html[1],
          id: "local" + i,
        };
      }

      return false;
    })
    .filter((item) => item);

  const { ref, width, height } = useResizeObserver();

  console.log(libs, selectedLibOptions);

  // gunzip is always last
  let names = [];
  const frameImports = orderScriptsByName(selectedLibOptions).map((o) => {
    const data = o.scriptData;
    console.log(o.name, o.scriptData.length);
    names.push(o.name);
    return o.wrapPrefix + data + o.wrapSuffix;
  });

  console.log("frameImports", names);

  const P5Templates = (
    <div className="p-2">
      {/* <div className="flex w-full mb-2">
        <a
          className="underline text-sm"
          href="https://processing.org/"
          target="_blank"
        >
          Processing
        </a>
      </div> */}
      <div className="flex space-x-2 mb-2 w-full justify-center">
        <div
          className="btn h-auto p-1.5"
          onClick={async () => {
            const requiredImports = [importIds.p5, importIds.gunzip];
            await getAndSaveImportsIfNeeded(requiredImports);
            setSource(EX_P5_1_SOURCE);
            setTempSource(EX_P5_1_SOURCE);
          }}
        >
          <img className="w-20" src={p51} />
        </div>
        <div
          className="btn h-auto p-1.5"
          onClick={async () => {
            const requiredImports = [
              importIds.three,
              importIds.threeOrbitControls,
              importIds.threeStats,
              importIds.gunzip,
            ];
            await getAndSaveImportsIfNeeded(requiredImports);
            setSource(EX_THREE_2_SOURCE);
            setTempSource(EX_THREE_2_SOURCE);
          }}
        >
          <img className="w-20" src={three2} />
        </div>
        {/* <div
          className="btn h-auto p-1.5"
          onClick={async () => {
            const requiredImports = [importIds.p5, importIds.gunzip];
            await getAndSaveImportsIfNeeded(requiredImports);
            setSelectedLibOptions(
              requiredImports.map((n) => {
                return libOptions.find((o) => o.name === n);
              })
            );
            setSource(EX_P5_3_SOURCE);
            setTempSource(EX_P5_3_SOURCE);
          }}
        >
          <img className="w-20" src={p53} />
        </div> */}
      </div>
    </div>
  );

  return (
    <div className="sm:border-r border-base-300 sm:h-full flex flex-col">
      <div className="flex items-center justify-between p-2 px-4">
        <div className="flex-1">
          <button
            className="btn btn-sm btn-ghost"
            onClick={() => {
              setShowFrame(false);
              setTimeout(() => {
                setShowFrame(true);
              }, 1);
            }}
          >
            <IoRefreshCircle className="mr-2" />
            Reload
          </button>
        </div>
        <div className="flex-1 text-center">
          <span className=" text-sm opacity-50">
            {width}px x {height}px
          </span>
        </div>
        <div className="flex-1 text-right">
          <button
            className="btn btn-sm btn-ghost"
            onClick={() => {
              const winHtml = renderedHtml;
              const winUrl = URL.createObjectURL(
                new Blob([winHtml], { type: "text/html" })
              );

              window.open(
                winUrl,
                "win",
                `width=${window.innerWidth},height=${window.innerHeight},screenX=0,screenY=0`
              );
            }}
          >
            <IoExpand className="mr-2" />
            Expand
          </button>
        </div>
      </div>
      <div className={"flex-1 relative flex p-4 pt-0"}>
        <div ref={ref} className="flex h-96 sm:h-full w-full bg-base-300">
          {source && showFrame ? (
            <RenderFrame
              height={height}
              width={width}
              source={source}
              imports={frameImports}
              localImports={localImportsData}
              htmlWrapper={
                wrappers?.find((wr) => wr.id === "html-wrap@1.0.0")?.html
              }
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-full w-full space-y-2 p-8">
              {isFetching ? (
                <span className="text-sm">Fetching imports...</span>
              ) : (
                <div className="flex flex-col flex-wrap justify-center">
                  {P5Templates}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RenderPanel;
