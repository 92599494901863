import create from "zustand";
import { persist } from "zustand/middleware";
import { DEFAULT_LIB_OPTIONS } from "./consts";

export type LocalImport = {
  data: string;
  name: string;
  id: string;
  wrapper: string;
};

export type LocalImports = Array<LocalImport>;

export type LibOption = {
  name: string;
  storageAddresses: {
    [key: number]: string;
  };
  scriptData: string;
  wrapPrefix: string;
  wrapSuffix: string;
  wrapType: number;
};

export type LibData = {
  name: string;
  content: string;
};

export type FrameAppState = {
  selectedIds: Array<string>;
  imports: any;

  libOptions: LibOption[];
  selectedLibOptions: LibOption[];
  libs: LibData[];

  localImports: LocalImports;
  selectedCreatorTab: number;
  source: string;
  tempSource: string;
  walletProvider: any;
  recentFrames: Array<string>;
  renderedHtml: string;
  publishCost: string;
  publishModalActive: boolean;
  tosConfirmed: boolean;

  setSelectedIds: (selectedIds: Array<string>) => void;
  setLocalImports: (localImports: LocalImports) => void;
  setImports: (imports: LocalImports) => void;

  setLibOptions: (libOptions: LibOption[]) => void;
  setSelectedLibOptions: (selectedLibOptions: LibOption[]) => void;
  setLibs: (libs: LibData[]) => void;

  setSelectedCreatorTab: (selectedCreatorTab: number) => void;
  setSource: (selectedIds: string) => void;
  setTempSource: (selectedIds: string) => void;
  setWalletProvider: (provider: any) => void;
  setRecentFrames: (frames: Array<string>) => void;
  setRenderedHtml: (html: string) => void;
  setPublishCost: (publishCost: string) => void;
  setPublishModalActive: (publishModalActive: boolean) => void;
  setTosConfirmed: (tosConfirmed: boolean) => void;
};

const useStore = create(
  persist(
    (set, get): FrameAppState => ({
      selectedIds: [],
      imports: [],

      libOptions: DEFAULT_LIB_OPTIONS,
      selectedLibOptions: [],
      libs: [],

      localImports: [],
      selectedCreatorTab: 0,
      source: "",
      tempSource: "",
      walletProvider: null,
      recentFrames: [],
      renderedHtml: "",
      publishCost: "",
      publishModalActive: false,
      tosConfirmed: false,

      // Write
      setSelectedIds: (selectedIds: Array<string>) => set({ selectedIds }),
      setImports: (imports: LocalImports) => set({ imports }),
      setLocalImports: (localImports: LocalImports) => set({ localImports }),
      setLibOptions: (libOptions: LibOption[]) => set({ libOptions }),
      setSelectedLibOptions: (selectedLibOptions: LibOption[]) =>
        set({ selectedLibOptions }),
      setLibs: (libs: LibData[]) => set({ libs }),

      setSelectedCreatorTab: (selectedCreatorTab: number) =>
        set({ selectedCreatorTab }),
      setSource: (source: string) => set({ source }),
      setTempSource: (tempSource: string) => set({ tempSource }),
      setWalletProvider: (walletProvider: any) => set({ walletProvider }),
      setRecentFrames: (recentFrames: Array<string>) => set({ recentFrames }),
      setRenderedHtml: (renderedHtml: string) => set({ renderedHtml }),
      setPublishCost: (publishCost: string) => set({ publishCost }),
      setPublishModalActive: (publishModalActive: boolean) =>
        set({ publishModalActive }),
      setTosConfirmed: (tosConfirmed: boolean) => set({ tosConfirmed }),
    }),
    {
      name: "frame-tools-r4",
      // getStorage: () => sessionStorage,
    }
  )
);

export default useStore;
