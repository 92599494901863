import shallow from "zustand/shallow";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/src-noconflict/ext-language_tools";
import { useContract, useSigner, useProvider, useNetwork } from "wagmi";
import { IoCloudUploadSharp, IoCloseCircle } from "react-icons/io5";
import { FrameDeployerABI, ScriptyBuilderABI } from "../contracts/abis";
import { DEFAULT_CHAIN_ID } from "../state/consts";

import CreatorTabs from "./CreatorTabs";
import ImportSelector from "./ImportSelector";

import useStore from "../state/store";
import { toBytes, formatEther } from "../utils/data";
import {
  getFrameInfraAddress,
  getEtherScanLink,
  createWrappedRequest,
  createEmptyWrappedRequest,
  getBufferSize,
} from "../utils/web3";

const CreatorPanel = () => {
  const {
    selectedCreatorTab,
    tempSource,
    source,
    setSource,
    setTempSource,
    publishCost,
    setPublishCost,
    setPublishModalActive,
    setTosConfirmed,
    selectedLibOptions,
  } = useStore(
    (state) => ({
      selectedCreatorTab: state.selectedCreatorTab,
      tempSource: state.tempSource,
      source: state.source,
      localImports: state.localImports,
      setSource: state.setSource,
      setTempSource: state.setTempSource,
      selectedIds: state.selectedIds,
      coreDeps: state.coreDeps,
      publishCost: state.publishCost,
      setPublishCost: state.setPublishCost,
      setPublishModalActive: state.setPublishModalActive,
      setTosConfirmed: state.setTosConfirmed,
      selectedLibOptions: state.selectedLibOptions,
    }),
    shallow
  );

  const provider = useProvider();
  const { data: signer } = useSigner();
  const { chain } = useNetwork();
  const chainId = chain?.id || DEFAULT_CHAIN_ID;

  const frameDeployer = useContract({
    addressOrName: getFrameInfraAddress("FrameDeployer", chainId),
    contractInterface: FrameDeployerABI,
    signerOrProvider: signer,
  });

  const scriptyBuilder = useContract({
    addressOrName: getFrameInfraAddress("ScriptyBuilder", chainId),
    contractInterface: ScriptyBuilderABI,
    signerOrProvider: signer,
  });

  const onPublish = async () => {
    setTosConfirmed(false);
    setPublishModalActive(true);
  };

  const handleKeyDown = async (event) => {
    const charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      event.preventDefault();
      setSource(tempSource);

      // if (chain) {
      //   const sourceContent = btoa(source);
      //   const sourceBufferSize = getBufferSize(sourceContent);
      //   const libsRequests = selectedLibOptions.map((lib) =>
      //     createWrappedRequest(
      //       lib.name,
      //       getFrameInfraAddress("FrameTools", chainId),
      //       lib.wrapper === "js-gzip-b64" ? 2 : 1
      //     )
      //   );
      //   const testName = "test" + Math.random() * Math.random();
      //   const sourceRequest = createWrappedRequest(
      //     testName + "-source",
      //     getFrameInfraAddress("FrameTools", chainId),
      //     1
      //   );
      //   const requests = libsRequests.concat([sourceRequest]);
      //   const libsBufferSize =
      //     await scriptyBuilder.getBufferSizeForURLSafeHTMLWrapped(libsRequests);
      //   const bufferSize = libsBufferSize.toNumber() + sourceBufferSize;

      //   console.log(bufferSize);

      //   const gasPrice = await provider.getGasPrice();
      //   const gas = await frameDeployer.estimateGas.createFrameWithScript(
      //     {
      //       name: testName,
      //       description: testName,
      //       symbol: testName,
      //     },
      //     toBytes(sourceContent),
      //     requests,
      //     bufferSize
      //   );

      //   const cost = Number(
      //     (gas.toNumber() * gasPrice.toNumber()) / 10 ** 11
      //   ).toFixed(4);

      //   setPublishCost(cost);
      // }
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center flex-wrap justify-between px-4 py-2 border-base-300 border-b">
        <CreatorTabs />
        <div className="flex space-x-2">
          {tempSource && (
            <button
              onClick={() => {
                setSource("");
                setTempSource("");
              }}
              className="btn btn-ghost btn-sm"
            >
              <IoCloseCircle className="mr-2" />
              Clear
            </button>
          )}
          <button class="btn btn-sm btn-primary" onClick={onPublish}>
            <IoCloudUploadSharp className="mr-2" />
            Publish NFT
            {publishCost ? (
              <span className="hidden sm:block ml-1">
                {"(" + publishCost + " ETH)"}
              </span>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
      {selectedCreatorTab === 0 && (
        <div
          className="flex-1 h-full relative"
          onKeyDown={handleKeyDown}
          contentEditable={true}
        >
          <AceEditor
            mode="javascript"
            theme="twilight"
            value={tempSource}
            width="100%"
            height={"100%"}
            onChange={(value) => {
              setTempSource(value);
            }}
            name="source1"
          />
        </div>
      )}
      {selectedCreatorTab === 1 && (
        <div className="flex-1 relative h-auto">
          <ImportSelector />
        </div>
      )}
    </div>
  );
};

export default CreatorPanel;
