const Tabs = ({ options, onChange, activeIndex }) => {
  return (
    <div className="flex items-center sm:space-x-2 flex-1">
      {options.map((o, i) => {
        return (
          <div
            key={i}
            onClick={() => {
              if (onChange) {
                onChange(i);
              }
            }}
            className={
              "btn btn-sm sm:btn-md btn-ghost " +
              (activeIndex === i ? "hover:bg-transparent" : "")
            }
          >
            <span className={activeIndex === i ? "opacity-100" : "opacity-50"}>
              {o}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
