import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useContract, useContractRead, useSigner, useNetwork } from "wagmi";
import { providers } from "ethers";
import download from "downloadjs";
import {
  IoCloudySharp,
  IoCloudDownload,
  IoEarth,
  IoDownload,
  IoDocumentText,
  IoCloudOffline,
} from "react-icons/io5";
import ethAddress from "ethereum-address";

import { DEFAULT_CHAIN_ID } from "../state/consts";
import { FrameABI } from "../contracts/abis";

import {
  getEtherScanLink,
  getMarketplaceLink,
  getMulticallAddress,
  renderWithMulticall,
  getRpcUrl,
  getChain,
} from "../utils/web3";

const FrameView = ({
  address,
  netVersion,
  onClose,
  header = true,
  onRender,
}) => {
  const [htmlResult, setHtmlResult] = useState("");
  const [frameName, setFrameName] = useState("");
  const [tempResult, setTempResult] = useState("");
  // const [pagesLoaded, setPagesLoaded] = useState(0);
  // const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasContractError, setHasContractError] = useState(false);

  const navigate = useNavigate();
  // const { chain } = useNetwork();
  const chainToUse = getChain(netVersion);
  const chainId = chainToUse.id;
  const rpcUrl = getRpcUrl(chainId);

  const urlChain = getChain(netVersion);
  const isAddressValid = ethAddress.isAddress(address);

  const readOnlyProvider = new providers.JsonRpcProvider(rpcUrl);

  const contract = useContract({
    addressOrName: address,
    contractInterface: FrameABI,
    signerOrProvider: readOnlyProvider,
    functionName: "tokenURI",
    args: [0],
  });
  // console.log(tokenURI);
  const onWrongNetwork = String(chainId) !== netVersion;

  let reqs = 0;

  useEffect(() => {
    async function fetchData() {
      if (!isAddressValid) {
        return setHasContractError(true);
      }

      if (!htmlResult && address && !onWrongNetwork) {
        setHasContractError(false);
        setIsLoading(true);
        let renderString = "";
        try {
          console.log("getting token uri");

          const tokenURI = await contract.tokenURI(0);
          console.log("decoding result");
          renderString = decodeURIComponent(
            decodeURIComponent(
              JSON.parse(tokenURI.replace("data:application/json,", ""))
                .animation_url
            )
          ).replace("data:text/html,", "");

          console.log("getting name");
          let fName = "";
          try {
            fName = await contract.name();
            setFrameName(fName);
          } catch (e) {
            console.log(e);
          }

          console.log("setting html");
          setHtmlResult(renderString);

          if (onRender) {
            onRender(address, netVersion, fName);
          }
        } catch (e) {
          console.log(e);
          setHasContractError(true);
        }
        setIsLoading(false);
        reqs = 0;
      }
    }

    if (!isLoading && reqs === 0) {
      reqs++;
      fetchData();
    }
  }, [
    htmlResult,
    address,
    isAddressValid,
    onWrongNetwork,
    contract,
    isLoading,
    onRender,
    netVersion,
    rpcUrl,
    chainId,
  ]);

  return (
    <div className="w-full " style={{ width: 760, maxWidth: "100%" }}>
      {/* header */}
      {header && (
        <div className="flex justify-between items-center min-h-12 px-2 border border-base-300 border-b-transparent relative">
          {!htmlResult && (
            <div className="px-2">
              {!htmlResult && !hasContractError && (
                <span className="text-xs sm:text-sm">Loading...</span>
              )}
            </div>
          )}

          {/* {isLoading && (
            <div className="absolute -bottom-4 left-0 w-full">
              <progress
                className="progress h-1 w-full"
                value={pagesLoaded}
                max={100}
              ></progress>
            </div>
          )} */}

          {htmlResult && (
            <div className="flex items-center h-full py-2 space-x-2">
              {frameName && (
                <div className="flex items-center font-semibold text-sm mr-2 ml-2 text-xs sm:text-sm">
                  {frameName}
                </div>
              )}
              <div className="flex badge badge-ghost text-xs sm:text-sm">
                <IoCloudySharp className="mr-2" /> {chainToUse?.name}
              </div>
              <div className="badge badge-ghost text-xs sm:text-sm">
                <IoEarth className="mr-2" />
                <a
                  target="_blank"
                  href={getMarketplaceLink(chainToUse?.id) + address + "/0"}
                  className="link"
                >
                  OpenSea
                </a>
              </div>

              <div className="badge badge-ghost text-xs sm:text-sm hidden sm:flex">
                <IoDownload className="mr-2" />{" "}
                <a
                  className="link"
                  onClick={() => {
                    download(
                      document.getElementById("frame")?.srcdoc,
                      (frameName || "frame") + ".html",
                      "text/plain"
                    );
                  }}
                >
                  <span className="block">Save</span>
                </a>
              </div>
            </div>
          )}

          {onClose && !isLoading && (
            <div>
              <button
                className="btn btn-ghost btn-sm text-xs sm:text-sm"
                onClick={() => {
                  navigate("/frames");
                }}
              >
                Close
              </button>
            </div>
          )}
        </div>
      )}

      <div
        className="relative border border-base-300 w-full"
        style={{ height: 760 }}
      >
        {isLoading && (
          <>
            {/* <div className="absolute -top-3 left-0 w-full">
              <progress
                className="progress h-1 w-full"
                value={pagesLoaded}
                max={totalPages}
              ></progress>
            </div> */}
            <div
              className="overflow-hidden relative mt-2"
              style={{
                height: "730px",
                width: "760px",
                maxWidth: "100%",
              }}
            >
              <p
                className="opacity-30 break-all absolute bottom-0 left-0"
                style={{ fontSize: "8px", lineHeight: "1.25" }}
              >
                {tempResult}
              </p>
            </div>
          </>
        )}

        {!isLoading && htmlResult && (
          <iframe
            id="frame"
            title="frame"
            className="border border-base-300 h-full w-full"
            style={{
              backgroundColor: "#fff",
            }}
            srcDoc={htmlResult}
          />
        )}

        {hasContractError && !isLoading && (
          <div className="h-full w-full flex flex-col justify-center items-center space-y-4">
            <IoCloudOffline className="text-2xl" />
            <span className="text-center break-words">
              Frame {address} <br /> not found on the {urlChain.name} network
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default FrameView;
