export const FrameFactoryABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newAddress",
        type: "address",
      },
    ],
    name: "FrameCreated",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "contract IFrameDataStoreFactory",
        name: "_frameDataStoreFactory",
        type: "address",
      },
      {
        components: [
          {
            internalType: "string",
            name: "wrapperKey",
            type: "string",
          },
          {
            internalType: "string",
            name: "key",
            type: "string",
          },
          {
            internalType: "address",
            name: "wrapperStore",
            type: "address",
          },
          {
            internalType: "address",
            name: "store",
            type: "address",
          },
        ],
        internalType: "struct IFrame.Asset[]",
        name: "_deps",
        type: "tuple[]",
      },
      {
        internalType: "bytes[]",
        name: "_newData",
        type: "bytes[]",
      },
      {
        internalType: "address",
        name: "_pageWrapStore",
        type: "address",
      },
      {
        internalType: "uint256[4][]",
        name: "_renderMap",
        type: "uint256[4][]",
      },
      {
        internalType: "string",
        name: "_name",
        type: "string",
      },
    ],
    name: "createFrameWithSource",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "libraryAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_libraryAddress",
        type: "address",
      },
    ],
    name: "setLibraryAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const FrameABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "bufferSize",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "description",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getApproved",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "string",
            name: "description",
            type: "string",
          },
          {
            internalType: "string",
            name: "symbol",
            type: "string",
          },
        ],
        internalType: "struct FrameMetadata",
        name: "_metadata",
        type: "tuple",
      },
      {
        internalType: "address",
        name: "_scriptyBuilderAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_bufferSize",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest[]",
        name: "_requests",
        type: "tuple[]",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "initialized",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
    ],
    name: "mintForOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "minted",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ownerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "requests",
    outputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "address",
        name: "contractAddress",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "contractData",
        type: "bytes",
      },
      {
        internalType: "uint8",
        name: "wrapType",
        type: "uint8",
      },
      {
        internalType: "bytes",
        name: "wrapPrefix",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "wrapSuffix",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "scriptContent",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "scriptyBuilderAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "nameString",
        type: "string",
      },
    ],
    name: "setName",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "symbolString",
        type: "string",
      },
    ],
    name: "setSymbol",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const FrameDataStoreABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_key",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "_startPage",
        type: "uint256",
      },
    ],
    name: "getAllDataFromPage",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_key",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "_startPage",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_endPage",
        type: "uint256",
      },
    ],
    name: "getData",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_key",
        type: "string",
      },
    ],
    name: "getMaxPageNumber",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_key",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "_startPage",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_endPage",
        type: "uint256",
      },
    ],
    name: "getSizeBetweenPages",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_key",
        type: "string",
      },
    ],
    name: "hasKey",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isLocked",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lock",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_key",
        type: "string",
      },
      {
        internalType: "uint128",
        name: "_pageNumber",
        type: "uint128",
      },
      {
        internalType: "bytes",
        name: "_b",
        type: "bytes",
      },
    ],
    name: "saveData",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_name",
        type: "string",
      },
    ],
    name: "setName",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_version",
        type: "string",
      },
    ],
    name: "setVersion",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "version",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const ScriptyStorageABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "NotScriptOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "ScriptExists",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "size",
        type: "uint256",
      },
    ],
    name: "ChunkStored",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        indexed: false,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "details",
        type: "bytes",
      },
    ],
    name: "ScriptCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        indexed: false,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "details",
        type: "bytes",
      },
    ],
    name: "ScriptDetailsUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "isVerified",
        type: "bool",
      },
    ],
    name: "ScriptVerificationUpdated",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "bytes",
        name: "chunk",
        type: "bytes",
      },
    ],
    name: "addChunkToScript",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "contentStore",
    outputs: [
      {
        internalType: "contract IContentStore",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "bytes",
        name: "details",
        type: "bytes",
      },
    ],
    name: "createScript",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "getScript",
    outputs: [
      {
        internalType: "bytes",
        name: "script",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
    ],
    name: "getScriptChunkPointers",
    outputs: [
      {
        internalType: "address[]",
        name: "pointers",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    name: "scripts",
    outputs: [
      {
        internalType: "bool",
        name: "isVerified",
        type: "bool",
      },
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "size",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "details",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_contentStoreAddress",
        type: "address",
      },
    ],
    name: "setContentStore",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "bytes",
        name: "details",
        type: "bytes",
      },
    ],
    name: "updateDetails",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "bool",
        name: "isVerified",
        type: "bool",
      },
    ],
    name: "updateScriptVerification",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const ScriptyBuilderABI = [
  {
    inputs: [],
    name: "InvalidRequestsLength",
    type: "error",
  },
  {
    inputs: [],
    name: "HTML_BASE64_DATA_URI_BYTES",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "HTML_BODY_CLOSED_RAW",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "HTML_BODY_CLOSED_URL_SAFE",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "HTML_BODY_OPEN_RAW",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "HTML_BODY_OPEN_URL_SAFE",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "HTML_TAG_RAW",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "HTML_TAG_URL_SAFE",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SCRIPT_INLINE_BYTES",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "URLS_RAW_BYTES",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "URLS_SAFE_BYTES",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct InlineScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
    ],
    name: "getBufferSizeForEncodedHTMLInline",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
    ],
    name: "getBufferSizeForEncodedHTMLWrapped",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct InlineScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
    ],
    name: "getBufferSizeForHTMLInline",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
    ],
    name: "getBufferSizeForHTMLWrapped",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
    ],
    name: "getBufferSizeForURLSafeHTMLWrapped",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct InlineScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "bufferSize",
        type: "uint256",
      },
    ],
    name: "getEncodedHTMLInline",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct InlineScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "bufferSize",
        type: "uint256",
      },
    ],
    name: "getEncodedHTMLInlineString",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "bufferSize",
        type: "uint256",
      },
    ],
    name: "getEncodedHTMLWrapped",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "bufferSize",
        type: "uint256",
      },
    ],
    name: "getEncodedHTMLWrappedString",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct InlineScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "bufferSize",
        type: "uint256",
      },
    ],
    name: "getHTMLInline",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct InlineScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "bufferSize",
        type: "uint256",
      },
    ],
    name: "getHTMLInlineString",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "bufferSize",
        type: "uint256",
      },
    ],
    name: "getHTMLWrapped",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "bufferSize",
        type: "uint256",
      },
    ],
    name: "getHTMLWrappedString",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "bufferSize",
        type: "uint256",
      },
    ],
    name: "getHTMLWrappedURLSafe",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct InlineScriptRequest",
        name: "request",
        type: "tuple",
      },
    ],
    name: "getInlineScriptSize",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest[]",
        name: "requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "bufferSize",
        type: "uint256",
      },
    ],
    name: "getURLSafeHTMLWrappedString",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest",
        name: "request",
        type: "tuple",
      },
    ],
    name: "getURLSafeWrappedScriptSize",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest",
        name: "request",
        type: "tuple",
      },
    ],
    name: "getWrappedScriptSize",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const FrameDeployerABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_frameFactory",
        type: "address",
      },
      {
        internalType: "address",
        name: "_scriptyBuilder",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newAddress",
        type: "address",
      },
    ],
    name: "FrameCreated",
    type: "event",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "string",
            name: "description",
            type: "string",
          },
          {
            internalType: "string",
            name: "symbol",
            type: "string",
          },
        ],
        internalType: "struct FrameMetadata",
        name: "_metadata",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest[]",
        name: "_requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "_bufferSize",
        type: "uint256",
      },
    ],
    name: "createFrame",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "string",
            name: "description",
            type: "string",
          },
          {
            internalType: "string",
            name: "symbol",
            type: "string",
          },
        ],
        internalType: "struct FrameMetadata",
        name: "_metadata",
        type: "tuple",
      },
      {
        internalType: "bytes",
        name: "_script",
        type: "bytes",
      },
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            internalType: "address",
            name: "contractAddress",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "contractData",
            type: "bytes",
          },
          {
            internalType: "uint8",
            name: "wrapType",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "wrapPrefix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "wrapSuffix",
            type: "bytes",
          },
          {
            internalType: "bytes",
            name: "scriptContent",
            type: "bytes",
          },
        ],
        internalType: "struct WrappedScriptRequest[]",
        name: "_requests",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "_requestsBufferSize",
        type: "uint256",
      },
    ],
    name: "createFrameWithScript",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "frameFactory",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "scriptyBuilder",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const defs = { FrameFactoryABI, FrameABI, FrameDataStoreABI, FrameDeployerABI };

export default defs;
