import { Contract, providers } from "ethers";
import { CHAINS, importIds, WRAPPER_MAP } from "../state/consts";
import { ADDRESSES_MAP } from "../contracts/addresses";
import { ScriptyStorageABI } from "../contracts/abis";
import { fromBytes, toBytes } from "../utils/data";

export const getEtherScanLink = (chainId) => {
  if (chainId === 1) {
    return "https://etherscan.io";
  }

  if (chainId === 4) {
    return "https://rinkeby.etherscan.io";
  }

  if (chainId === 5) {
    return "https://goerli.etherscan.io";
  }

  if (chainId === 42161) {
    return "https://arbiscan.io";
  }
};

export const getMarketplaceLink = (chainId) => {
  if (chainId === 1) {
    return "https://opensea.io/assets/";
  }

  if (chainId === 5) {
    return "https://testnets.opensea.io/assets/goerli/";
  }

  if (chainId === 42161) {
    return "https://testnets.opensea.io/assets/goerli/";
  }
};

export const getChain = (chainId) => {
  return CHAINS.find((c) => c.id === Number(chainId));
};

export const getRpcUrl = (chainId) => {
  if (chainId === 5) {
    return "https://eth-goerli.g.alchemy.com/v2/bT2Gi8WI5-jp62yzI6H2yakitNqytvvW";
  } else {
    return "http://localhost:8545";
  }
};

export const getFrameInfraAddress = (name, chainId) => {
  if (chainId) {
    return ADDRESSES_MAP[name][String(chainId)];
  } else {
    return "";
  }
};
export const createWrappedRequest = (libOption, contentStore) => ({
  name: libOption.name,
  contractAddress: contentStore,
  contractData: toBytes(""),
  wrapType: libOption.wrapType,
  wrapPrefix:
    libOption.wrapType === 4
      ? toBytes(encodeURIComponent(encodeURIComponent(libOption.wrapPrefix)))
      : toBytes(""),
  wrapSuffix:
    libOption.wrapType === 4
      ? toBytes(encodeURIComponent(encodeURIComponent(libOption.wrapSuffix)))
      : toBytes(""),
  scriptContent: toBytes(""),
});

export const getSourceBufferSize = (data) => {
  let bufferSizeLocal = 0;
  bufferSizeLocal += new Blob([
    encodeURIComponent(
      encodeURIComponent(
        WRAPPER_MAP["jsmod-b64"][0] + WRAPPER_MAP["jsmod-b64"][1]
      )
    ),
  ]).size;
  bufferSizeLocal += new Blob([data]).size;

  return bufferSizeLocal;
};

export const getLibContent = async (name, chainId) => {
  console.log("getLibContent");
  const rpcUrl = getRpcUrl(chainId);
  const readOnlyProvider = new providers.JsonRpcProvider(rpcUrl);
  const scriptyStorageContract = new Contract(
    getFrameInfraAddress("FrameTools", chainId),
    ScriptyStorageABI,
    readOnlyProvider
  );

  const libData = fromBytes(
    await scriptyStorageContract.getScript(name, toBytes(""))
  );

  return libData;
};

// Ensures gunzip and inline imports are submitted in the correct order
export const orderScriptsByName = (scripts) =>
  scripts.sort((a) => {
    if (a.name === importIds.gunzip) {
      return 1;
    }
    return -1;
  });
